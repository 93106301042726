import {
  BONUS_INDEXES,
  GRID_CELLS_LENGTH,
  GRID_CELLS_CENTER_INDEX,
  X_AXIS_LEFT_EDGE_INDEXES,
  X_AXIS_INCREMENTATION,
  Y_AXIS_INCREMENTATION,
} from '@/game/classes/constants';
import { getRandomInt } from '@/utils/general';

export class Grid implements GridClass {
  cells;

  constructor(gridType: GridType) {
    this.setCells();
    this.setBonuses(gridType);
  }

  private setCells() {
    this.cells = new Array(GRID_CELLS_LENGTH)
      .fill({})
      .map((emptyCell, index) => ({
        index,
      }));
  }

  private setBonuses(gridType: GridType) {
    const entries = Object.entries(BONUS_INDEXES);
    entries.forEach(([bonus, indexes]) => {
      for (const index of indexes) {
        if (gridType === 'Random') {
          this.assignBonusRandomly(bonus as Bonus);
        } else {
          this.cells[index].bonus = bonus as Bonus;
        }
      }
    });
  }

  private assignBonusRandomly(bonus: Bonus) {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const randomIndex = getRandomInt({ max: this.cells.length - 1 });
      if (
        !this.cells[randomIndex].bonus &&
        randomIndex !== GRID_CELLS_CENTER_INDEX
      ) {
        this.cells[randomIndex].bonus = bonus;
        break;
      }
    }
  }

  getAxisCells(cellIndex: number, axisType: AxisType): Cell[] {
    const axisCells = [];
    if (axisType === 'x') {
      let xStart = 0;
      for (const index of X_AXIS_LEFT_EDGE_INDEXES) {
        if (cellIndex >= index) {
          xStart = index;
          break;
        }
      }
      for (let i = xStart; i < xStart + 15; i += X_AXIS_INCREMENTATION)
        axisCells.push(this.cells[i]);
    } else {
      let yStart = cellIndex;
      while (yStart > Y_AXIS_INCREMENTATION - 1)
        yStart -= Y_AXIS_INCREMENTATION;
      for (let i = yStart; i < yStart + 211; i += Y_AXIS_INCREMENTATION)
        axisCells.push(this.cells[i]);
    }
    return axisCells;
  }

  setLastPlayed(moveTiles: CelledTile[]) {
    this.cells
      .filter((cell) => cell.lastPlayed)
      .forEach((cell) => delete cell.lastPlayed);

    moveTiles.forEach(({ cellIndex, tile }) => {
      this.cells[cellIndex] = {
        ...this.cells[cellIndex],
        ...{ tile },
        ...{ lastPlayed: true },
      };
    });
  }
}
