
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GameLayoutCell',

  props: {
    bonus: {
      type: String as () => 'DW' | 'TW' | 'DL' | 'TL',
      default: undefined,
    },
    isUnclickable: {
      type: Boolean,
      default: false,
    },
  },
});
