import { TILE_AMOUNTS } from '@/game/classes/constants';
import { Grid, Player } from '.';
import { getRandomInt } from '@/utils/general';
import { shuffleArray, cutRandomItemsFromArray } from '@/utils/array';

export class Match implements MatchClass {
  tiles;
  players;
  grid;

  constructor(gridType: GridType) {
    this.setTiles();
    this.players = [new Player(), new Player()];
    this.setPlayerTiles();
    this.grid = new Grid(gridType);
  }

  *generateTileId() {
    let index = 0;
    while (true) yield index++;
  }

  private setTiles() {
    this.tiles = [];
    const tileIdGenerator = this.generateTileId();
    Object.entries(TILE_AMOUNTS).forEach(([letter, amount]) => {
      this.tiles = [
        ...this.tiles,
        ...[...Array(amount)].map(() => [tileIdGenerator.next().value, letter]),
      ];
    });
  }

  private setPlayerTiles() {
    for (let i = 0; i < this.players.length; i++) {
      shuffleArray(this.tiles);
      this.players[i].tiles = this.tiles.splice(
        getRandomInt({ max: this.tiles.length - 8 }),
        7
      );
    }
  }

  refreshPlayerTiles(playerIndex: number, moveTiles: CelledTile[]) {
    this.players[playerIndex].tiles = [
      ...this.players[playerIndex].tiles.filter(
        (tile) =>
          !moveTiles.find((celledTile) => celledTile.tile[0] === tile[0])
      ),
      ...cutRandomItemsFromArray(this.tiles, moveTiles.length),
    ];
  }

  handleMoveSave({ playerIndex, moveTiles, points }: NewMove) {
    this.grid.setLastPlayed(moveTiles);
    this.players[playerIndex].setScore(points);
    this.players[playerIndex].setIsPassed(false);
    this.refreshPlayerTiles(playerIndex, moveTiles);
  }
}
