import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-433c202c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Game__players PageWidth" }
const _hoisted_2 = {
  key: 1,
  class: "Game__layout-tooltip"
}
const _hoisted_3 = { class: "Game__footer Grid__footer PageWidth" }
const _hoisted_4 = { class: "Game__footer-cells Grid__footer-cells" }
const _hoisted_5 = { viewBox: "0 0 66 66" }
const _hoisted_6 = ["y"]
const _hoisted_7 = { class: "Game__footer-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GameHeader = _resolveComponent("GameHeader")!
  const _component_GamePlayer = _resolveComponent("GamePlayer")!
  const _component_GameTile = _resolveComponent("GameTile")!
  const _component_game_layout_cell = _resolveComponent("game-layout-cell")!
  const _component_GameButton = _resolveComponent("GameButton")!
  const _component_GameEndScreen = _resolveComponent("GameEndScreen")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", {
      class: _normalizeClass(['Game', _ctx.isEnded && 'Game--blurred'])
    }, [
      _createVNode(_component_GameHeader),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.match.players, (player, playerIndex) => {
          return (_openBlock(), _createBlock(_component_GamePlayer, {
            key: playerIndex,
            player: player,
            "is-opponent": _ctx.OPPONENT_INDEX === playerIndex,
            "is-active": !_ctx.isEnded && _ctx.turnPlayerIndex === playerIndex
          }, null, 8, ["player", "is-opponent", "is-active"]))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([
        'Game__layout Grid__layout PageWidth',
        _ctx.selectedCelledTile && 'Game__layout--selectable',
      ])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.match.grid.cells, ({ index, bonus, tile, lastPlayed }) => {
          return (_openBlock(), _createBlock(_component_game_layout_cell, {
            key: index,
            bonus: bonus,
            "is-unclickable": !!tile,
            onClick: ($event: any) => (_ctx.onClickCell(index))
          }, {
            default: _withCtx(() => [
              (tile || _ctx.getMoveTile(index))
                ? (_openBlock(), _createBlock(_component_GameTile, {
                    key: 0,
                    tile: tile || _ctx.getMoveTile(index),
                    "is-selected": index === _ctx.selectedCelledTile?.cellIndex,
                    "is-last-played": lastPlayed,
                    "is-moveable": 
            _ctx.moveTiles.some((celledTile) => celledTile.cellIndex == index)
          ,
                    onClick: _withModifiers(($event: any) => (_ctx.onClickTile(_ctx.getMoveTile(index), index)), ["stop"])
                  }, null, 8, ["tile", "is-selected", "is-last-played", "is-moveable", "onClick"]))
                : _createCommentVNode("", true),
              (_ctx.tooltipIndex === index)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.cachedMove.newMove.points), 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["bonus", "is-unclickable", "onClick"]))
        }), 128))
      ], 2),
      _createElementVNode("footer", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", null, [
              (_openBlock(), _createElementBlock("svg", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
                  [_ctx.match.tiles.length, '35%'],
                  ['left', '65%'],
                ], ([text, y]) => {
                  return (_openBlock(), _createElementBlock("text", {
                    key: y,
                    x: "50%",
                    y: y,
                    "text-anchor": "middle",
                    "dominant-baseline": "middle"
                  }, _toDisplayString(text), 9, _hoisted_6))
                }), 128))
              ]))
            ]),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(7, (index) => {
              return _createElementVNode("li", {
                key: index,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickFooterCell && _ctx.onClickFooterCell(...args)))
              }, [
                (_ctx.getPlayerTile(index))
                  ? (_openBlock(), _createBlock(_component_GameTile, {
                      key: 0,
                      tile: _ctx.getPlayerTile(index),
                      "is-selected": 
                _ctx.getPlayerTile(index)?.[0] === _ctx.selectedCelledTile?.tile?.[0]
              ,
                      onClick: _withModifiers(($event: any) => (_ctx.onClickTile(_ctx.getPlayerTile(index))), ["stop"])
                    }, null, 8, ["tile", "is-selected", "onClick"]))
                  : _createCommentVNode("", true)
              ])
            }), 64))
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, ({ text, onClick }) => {
              return (_openBlock(), _createBlock(_component_GameButton, {
                key: text,
                text: text,
                class: "Text__normal",
                onClick: ($event: any) => (onClick())
              }, null, 8, ["text", "onClick"]))
            }), 128))
          ])
        ])
      ])
    ], 2),
    (_ctx.isEnded)
      ? (_openBlock(), _createBlock(_component_GameEndScreen, {
          key: 0,
          "you-are-winner": _ctx.player.isWinner,
          "opponent-is-winner": _ctx.opponent.isWinner
        }, null, 8, ["you-are-winner", "opponent-is-winner"]))
      : _createCommentVNode("", true)
  ], 64))
}