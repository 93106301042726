<template>
  <path
    fill="#e5e5e5"
    d="M30 12L24 18L18 12L24 6ZM36 18L30 12L36 6L42 12ZM30 48L36 42L42 48L36 54ZM24 42L30 48L24 54L18 48ZM18 24L12 30L6 24L12 18ZM48 30L42 24L48 18L54 24ZM42 36L48 30L54 36L48 42ZM12 30L18 36L12 42L6 36Z"
  /><path
    fill="#7dcc66"
    d="M8 12a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M44 12a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M44 48a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M8 48a4,4 0 1,1 8,0a4,4 0 1,1 -8,0"
  /><path
    fill="#4a9932"
    d="M30 18L30 27L24 18ZM42 30L33 30L42 24ZM30 42L30 33L36 42ZM18 30L27 30L18 36Z"
  />
</template>
