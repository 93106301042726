import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["d"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tile)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        class: _normalizeClass([
      'GameTile',
      _ctx.isSelected && 'GameTile--selected',
      _ctx.isLastPlayed && 'GameTile--last-played',
      _ctx.isMoveable && 'GameTile--moveable',
    ]),
        viewBox: "0 0 21 21"
      }, [
        _createElementVNode("path", {
          d: _ctx.tilePath.letter
        }, null, 8, _hoisted_1),
        _createElementVNode("path", {
          d: _ctx.tilePath.points
        }, null, 8, _hoisted_2)
      ], 2))
    : _createCommentVNode("", true)
}