<template>
  <path
    fill="#545454"
    d="M18 6L30 6L30 18ZM42 6L42 18L30 18ZM42 54L30 54L30 42ZM18 54L18 42L30 42ZM6 18L18 18L18 30ZM54 18L54 30L42 30ZM54 42L42 42L42 30ZM6 42L6 30L18 30Z"
  /><path
    fill="#e8e8e8"
    d="M8 12a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M44 12a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M44 48a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M8 48a4,4 0 1,1 8,0a4,4 0 1,1 -8,0"
  /><path
    fill="#d17580"
    d="M18 18L30 18L30 26.4L22.8 22.8L26.4 30L18 30ZM42 18L42 30L33.6 30L37.2 22.8L30 26.4L30 18ZM42 42L30 42L30 33.6L37.2 37.2L33.6 30L42 30ZM18 42L18 30L26.4 30L22.8 37.2L30 33.6L30 42Z"
  />
</template>
