
import { defineComponent } from 'vue';
import GameTile from '@/game/GameTile.vue';

export default defineComponent({
  name: 'GameHeader',

  components: {
    GameTile,
  },

  computed: {
    tiles() {
      return [
        [-1, 'W'],
        [-1, 'O'],
        [-1, 'R'],
        [-1, 'D'],
        [-1, 'S'],
      ] as Tile[];
    },
  },
});
