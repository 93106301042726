
import gsap from 'gsap';

import { defineComponent, PropType } from 'vue';
import { BOT_NAMES } from './constants';
import { getRandomInt } from '@/utils/general';

import GameAvatar from '@/game/game-avatar/GameAvatar.vue';

export default defineComponent({
  name: 'GamePlayer',

  components: {
    GameAvatar,
  },

  props: {
    player: {
      type: Object as PropType<PlayerClass>,
      required: true,
    },
    isOpponent: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      score: 0,
    };
  },

  computed: {
    playerAvatarIndex() {
      return this.isOpponent
        ? getRandomInt({ max: BOT_NAMES.length - 1 }) + 1
        : 0;
    },

    playerName() {
      return this.isOpponent ? BOT_NAMES[this.playerAvatarIndex - 1] : 'You';
    },
  },

  watch: {
    async 'player.score'(newVal, oldVal) {
      this.score = newVal;
      await this.$nextTick();
      gsap.from(this.$refs.score, {
        textContent: oldVal,
        snap: { textContent: 1 },
      });
    },
  },
});
