import { state } from './state';
import { getDefaultState } from './default_state';
import { setPromise } from '@/utils/promise';
import { sleep } from '@/utils/general';

async function triggerDialog(
  newDialog: Dialog
): Promise<{ isConfirmed: boolean }> {
  state.dialog = { ...newDialog, isTriggered: true };
  await setPromise(() => state.dialog.isConfirmed !== undefined);
  const newIsConfirmedValue = !!state.dialog.isConfirmed;
  state.dialog = getDefaultState().dialog;
  return { isConfirmed: newIsConfirmedValue };
}

function resolveDialog(isConfirmed: boolean) {
  state.dialog.isConfirmed = isConfirmed;
  state.dialog.isTriggered = false;
}

function spliceToast(index: number) {
  state.toasts.splice(
    state.toasts.findIndex((toast) => toast.index === index),
    1
  );
}

async function pushToast(toast: Toast) {
  toast.index = Number(new Date());
  if (state.toasts.length === 10) state.toasts.shift();
  state.toasts.push(toast);
  await sleep(5000);
  spliceToast(toast.index);
}

export { triggerDialog, resolveDialog, spliceToast, pushToast };
