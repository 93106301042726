import { DICTIONARY } from './_dictionary';

const TILE_AMOUNTS: TileAmounts = {
  A: 10,
  B: 2,
  C: 2,
  D: 5,
  E: 12,
  F: 2,
  G: 3,
  H: 3,
  I: 9,
  J: 1,
  K: 1,
  L: 4,
  M: 2,
  N: 6,
  O: 7,
  P: 2,
  Q: 1,
  R: 6,
  S: 5,
  T: 7,
  U: 4,
  V: 2,
  W: 2,
  X: 1,
  Y: 2,
  Z: 1,
};

const TILE_POINTS: TilePoints = {
  A: 1,
  B: 4,
  C: 4,
  D: 2,
  E: 1,
  F: 4,
  G: 3,
  H: 4,
  I: 1,
  J: 10,
  K: 5,
  L: 1,
  M: 3,
  N: 1,
  O: 1,
  P: 4,
  Q: 10,
  R: 1,
  S: 1,
  T: 1,
  U: 2,
  V: 4,
  W: 4,
  X: 8,
  Y: 4,
  Z: 10,
};

const BONUS_INDEXES: BonusIndexes = {
  DW: [32, 42, 52, 64, 70, 108, 116, 154, 160, 172, 182, 192],
  TW: [4, 10, 60, 74, 150, 164, 214, 220],
  DL: [
    7, 16, 28, 36, 38, 66, 68, 92, 94, 100, 102, 105, 119, 122, 124, 130, 132,
    156, 158, 186, 188, 196, 208, 217,
  ],
  TL: [
    0, 14, 20, 24, 48, 56, 76, 80, 84, 88, 136, 140, 144, 148, 168, 176, 200,
    204, 210, 224,
  ],
};

const X_AXIS_LEFT_EDGE_INDEXES: number[] = [
  210, 195, 180, 165, 150, 135, 120, 105, 90, 75, 60, 45, 30, 15, 0,
];

const GRID_CELLS_START = 0;

const GRID_CELLS_LENGTH = 225;

const GRID_CELLS_CENTER_INDEX = (GRID_CELLS_LENGTH - 1) / 2;

const X_AXIS_INCREMENTATION = 1;

const Y_AXIS_INCREMENTATION = 15;

const AUTO_MOVE_ITERATION_TIMEOUT = 10000;

const AUTO_MOVE_POINTS_MIN = 8;

const AUTO_MOVE_LENGTH_MIN = 2;

const PLAYER_INDEX = 0;

const OPPONENT_INDEX = 1;

export {
  DICTIONARY,
  TILE_AMOUNTS,
  TILE_POINTS,
  BONUS_INDEXES,
  GRID_CELLS_START,
  GRID_CELLS_LENGTH,
  GRID_CELLS_CENTER_INDEX,
  X_AXIS_LEFT_EDGE_INDEXES,
  X_AXIS_INCREMENTATION,
  Y_AXIS_INCREMENTATION,
  AUTO_MOVE_ITERATION_TIMEOUT,
  AUTO_MOVE_POINTS_MIN,
  AUTO_MOVE_LENGTH_MIN,
  PLAYER_INDEX,
  OPPONENT_INDEX,
};
