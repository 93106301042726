import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a19d8df"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "TheToastGroup"
}
const _hoisted_2 = { class: "Text__small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.toasts.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("ul", {
          class: _normalizeClass([
        'TheToastGroup__list PageWidth',
        _ctx.isTouched && 'TheToastGroup__list--touched',
      ]),
          onTouchstart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isTouched = true)),
          onTouchend: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isTouched = false))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, ({ body, mood, index }) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: _normalizeClass(`TheToastGroup__item PageWidth TheToastGroup__item--${mood}`)
            }, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(body), 1)
            ], 2))
          }), 128))
        ], 34)
      ]))
    : _createCommentVNode("", true)
}