
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GameEndScreen',

  props: {
    youAreWinner: {
      type: Boolean,
      default: false,
    },
    opponentIsWinner: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    endText() {
      switch (true) {
        case this.youAreWinner && this.opponentIsWinner:
          return `It's a tie 👔`;
        case this.youAreWinner:
          return `You won 🏆`;
        default:
          return `You lost 🦨`;
      }
    },
  },
});
