function setPromise(resolveCondition: () => boolean): Promise<boolean> {
  return new Promise((resolve) => {
    const promiseInterval = setInterval(() => {
      if (!resolveCondition()) return;
      resolve(true);
      clearInterval(promiseInterval);
    }, 150);
  });
}

export { setPromise };
