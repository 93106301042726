
import { defineComponent } from 'vue';
import Avatar0 from '@/game/game-avatar/GameAvatar0.vue';
import Avatar1 from '@/game/game-avatar/GameAvatar1.vue';
import Avatar2 from '@/game/game-avatar/GameAvatar2.vue';
import Avatar3 from '@/game/game-avatar/GameAvatar3.vue';
import Avatar4 from '@/game/game-avatar/GameAvatar4.vue';
import Avatar5 from '@/game/game-avatar/GameAvatar5.vue';
import Avatar6 from '@/game/game-avatar/GameAvatar6.vue';
import Avatar7 from '@/game/game-avatar/GameAvatar7.vue';
import Avatar8 from '@/game/game-avatar/GameAvatar8.vue';
import Avatar9 from '@/game/game-avatar/GameAvatar9.vue';
import Avatar10 from '@/game/game-avatar/GameAvatar10.vue';
import Avatar11 from '@/game/game-avatar/GameAvatar11.vue';
import Avatar12 from '@/game/game-avatar/GameAvatar12.vue';
import Avatar13 from '@/game/game-avatar/GameAvatar13.vue';

export default defineComponent({
  name: 'GameAvatar',
  components: {
    Avatar0,
    Avatar1,
    Avatar2,
    Avatar3,
    Avatar4,
    Avatar5,
    Avatar6,
    Avatar7,
    Avatar8,
    Avatar9,
    Avatar10,
    Avatar11,
    Avatar12,
    Avatar13,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});
