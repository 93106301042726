import {
  GRID_CELLS_START,
  GRID_CELLS_LENGTH,
  GRID_CELLS_CENTER_INDEX,
  X_AXIS_LEFT_EDGE_INDEXES,
  X_AXIS_INCREMENTATION,
  Y_AXIS_INCREMENTATION,
  AUTO_MOVE_ITERATION_TIMEOUT,
  AUTO_MOVE_POINTS_MIN,
  AUTO_MOVE_LENGTH_MIN,
} from '@/game/classes/constants';
import { Move } from '.';
import { getRandomInt } from '@/utils/general';

export class AutoMove implements AutoMoveClass {
  grid;
  tiles;
  playableCellIndexes;
  moveTiles;
  words;
  points;

  constructor(grid: Cell[], tiles: Tile[]) {
    this.grid = grid;
    this.tiles = tiles;
    this.setPlayableCellIndexes();
    this.setMoveVariables();
  }

  private setPlayableCellIndexes() {
    let cellIndexesToCheck: number[] = [];

    this.grid.cells.forEach(({ tile, index }) => {
      if (!tile) return;
      if (!X_AXIS_LEFT_EDGE_INDEXES.includes(index + X_AXIS_INCREMENTATION))
        cellIndexesToCheck.push(index + X_AXIS_INCREMENTATION);
      if (!X_AXIS_LEFT_EDGE_INDEXES.includes(index))
        cellIndexesToCheck.push(index - X_AXIS_INCREMENTATION);
      cellIndexesToCheck.push(index + Y_AXIS_INCREMENTATION);
      cellIndexesToCheck.push(index - Y_AXIS_INCREMENTATION);
    });

    this.playableCellIndexes = [];

    if (cellIndexesToCheck.length == 0) {
      this.playableCellIndexes.push(GRID_CELLS_CENTER_INDEX);
      return;
    }

    cellIndexesToCheck = [...new Set(cellIndexesToCheck)];

    for (const cellIndex of cellIndexesToCheck) {
      if (this.grid.cells[cellIndex] && !this.grid.cells[cellIndex].tile) {
        this.playableCellIndexes.push(cellIndex);
      }
    }
  }

  private setMoveVariables() {
    let i = 0;
    let cachedMove;

    while (i < AUTO_MOVE_ITERATION_TIMEOUT) {
      try {
        const newMoveTiles = this.getNewMoveTiles();
        const { words, points } = new Move(this.grid, newMoveTiles);

        if (
          points < AUTO_MOVE_POINTS_MIN ||
          newMoveTiles.length < AUTO_MOVE_LENGTH_MIN
        ) {
          if (!cachedMove) cachedMove = { newMoveTiles, words, points };
          throw 'Not enough points';
        }

        this.moveTiles = newMoveTiles;
        this.words = words;
        this.points = points;
        break;
      } catch (error) {
        // no need to handle error
      }
      i++;
    }

    if (!this.moveTiles) {
      if (cachedMove) {
        this.moveTiles = cachedMove.newMoveTiles;
        this.words = cachedMove.words;
        this.points = cachedMove.points;
      } else {
        throw "Can't generate a move";
      }
    }
  }

  private getNewMoveTiles() {
    const newMoveTiles = [];
    const tilesCopy = [...this.tiles];
    const startCellIndex =
      this.playableCellIndexes[
        getRandomInt({ max: this.playableCellIndexes.length - 1 })
      ];
    const wordLength = getRandomInt({ min: 1, max: tilesCopy.length - 1 });
    const { condition, incrementation } = this.getCellIterationValues(
      startCellIndex,
      wordLength
    );

    for (
      let cellIndex = startCellIndex;
      condition(cellIndex);
      cellIndex += incrementation
    ) {
      if (!this.grid.cells[cellIndex].tile)
        newMoveTiles.push({
          cellIndex,
          tile: tilesCopy.splice(
            getRandomInt({ max: tilesCopy.length - 1 }),
            1
          )[0],
        });
    }

    return newMoveTiles;
  }

  private getCellIterationValues(startCellIndex: number, wordLength: number) {
    return [
      // to right
      {
        condition(cellIndex) {
          if (
            startCellIndex !== cellIndex &&
            !X_AXIS_LEFT_EDGE_INDEXES.includes(
              cellIndex + X_AXIS_INCREMENTATION
            )
          )
            return false;
          return (
            cellIndex < startCellIndex + wordLength &&
            cellIndex < GRID_CELLS_LENGTH
          );
        },
        incrementation: X_AXIS_INCREMENTATION,
      },
      // to left
      {
        condition(cellIndex) {
          if (
            startCellIndex !== cellIndex &&
            !X_AXIS_LEFT_EDGE_INDEXES.includes(cellIndex)
          )
            return false;
          return (
            cellIndex > startCellIndex - wordLength &&
            cellIndex >= GRID_CELLS_START
          );
        },
        incrementation: X_AXIS_INCREMENTATION * -1,
      },
      // to bottom
      {
        condition: (cellIndex) =>
          cellIndex < startCellIndex + wordLength * Y_AXIS_INCREMENTATION &&
          cellIndex < GRID_CELLS_LENGTH,

        incrementation: Y_AXIS_INCREMENTATION,
      },
      //to top
      {
        condition: (cellIndex) =>
          cellIndex > startCellIndex - wordLength * Y_AXIS_INCREMENTATION &&
          cellIndex >= GRID_CELLS_START,

        incrementation: Y_AXIS_INCREMENTATION * -1,
      },
    ][getRandomInt({ max: 3 })];
  }
}
