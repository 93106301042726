import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheDialog = _resolveComponent("TheDialog")!
  const _component_TheToastGroup = _resolveComponent("TheToastGroup")!
  const _component_GameRoot = _resolveComponent("GameRoot")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheDialog),
    _createVNode(_component_TheToastGroup),
    _createVNode(_component_GameRoot)
  ], 64))
}