
import { defineComponent } from 'vue';
import { state } from '@/store/state';
import { resolveDialog } from '@/store/actions';

export default defineComponent({
  name: 'TheDialog',

  computed: {
    dialog() {
      return state.dialog;
    },
  },

  methods: {
    resolveDialog(isConfirmed: boolean) {
      return resolveDialog(isConfirmed);
    },

    onClickOutside() {
      this.resolveDialog(false);
    },
  },
});
