import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f27ee43"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "GameAvatar",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 60 60"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.name)))
    ], 1024))
  ]))
}