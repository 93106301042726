<template>
  <path
    fill="#997832"
    d="M20 12a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M32 12a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M32 48a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M20 48a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M8 24a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M44 24a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M44 36a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M8 36a4,4 0 1,1 8,0a4,4 0 1,1 -8,0"
  /><path
    fill="#ccab66"
    d="M6 18L6 6L12 6ZM42 6L54 6L54 12ZM54 42L54 54L48 54ZM18 54L6 54L6 48Z"
  /><path
    fill="#e5d5b2"
    d="M30 18L30 27L24 18ZM42 30L33 30L42 24ZM30 42L30 33L36 42ZM18 30L27 30L18 36Z"
  />
</template>
