<template>
  <path
    fill="#d7e5b2"
    d="M30 18L18 18L18 6ZM30 18L30 6L42 6ZM30 42L42 42L42 54ZM30 42L30 54L18 54ZM18 30L6 30L6 18ZM42 30L42 18L54 18ZM42 30L54 30L54 42ZM18 30L18 42L6 42Z"
  /><path
    fill="#4c4c4c"
    d="M18 12L12 18L6 12L12 6ZM48 18L42 12L48 6L54 12ZM42 48L48 42L54 48L48 54ZM12 42L18 48L12 54L6 48Z"
  /><path
    fill="#afcc66"
    d="M18 18L30 18L30 24L18 24ZM18 24L24 24L24 30L18 30ZM30 24L24 30L24 24ZM42 18L42 30L36 30L36 18ZM36 18L36 24L30 24L30 18ZM36 30L30 24L36 24ZM42 42L30 42L30 36L42 36ZM42 36L36 36L36 30L42 30ZM30 36L36 30L36 36ZM18 42L18 30L24 30L24 42ZM24 42L24 36L30 36L30 42ZM24 30L30 36L24 36Z"
  />
</template>
