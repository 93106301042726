
import { defineComponent } from 'vue';
import { getRandomInt, sleep } from '@/utils/general';
import { shuffleArray } from '@/utils/array';
import { pushToast, triggerDialog } from '@/store/actions';
import { AutoMove, Match, Move } from '@/game/classes';
import { PLAYER_INDEX, OPPONENT_INDEX } from '@/game/classes/constants';
import { AUTO_MOVE_DELAY } from '@/game/constants';

import GameHeader from '@/game/GameHeader.vue';
import GamePlayer from '@/game/GamePlayer.vue';
import GameLayoutCell from '@/game/GameLayoutCell.vue';
import GameTile from '@/game/GameTile.vue';
import GameButton from '@/game/GameButton.vue';
import GameEndScreen from '@/game/GameEndScreen.vue';

export default defineComponent({
  name: 'GameRoot',

  components: {
    GameHeader,
    GamePlayer,
    GameLayoutCell,
    GameTile,
    GameButton,
    GameEndScreen,
  },

  data() {
    const gridType = ['Classic', 'Random'][
      getRandomInt({ max: 1 })
    ] as GridType;
    const match = new Match(gridType);
    return {
      OPPONENT_INDEX,
      match,
      player: match.players[PLAYER_INDEX],
      opponent: match.players[OPPONENT_INDEX],
      turnPlayerIndex: PLAYER_INDEX,

      selectedCelledTile: null as CelledTile,
      moveTiles: [] as CelledTile[],
      playerTiles: [...match.players[PLAYER_INDEX].tiles] as Tile[],

      cachedMove: null as CachedMove,
    };
  },

  computed: {
    isEnded() {
      return this.player.isWinner || this.opponent.isWinner;
    },

    buttons() {
      return [
        { text: 'Resign', isShown: true, onClick: this.resignMatch },
        {
          text: 'Pass',
          isShown: this.moveTiles.length === 0,
          onClick: this.passMatch,
        },
        {
          text: 'Shuffle',
          isShown: this.moveTiles.length === 0,
          onClick: this.shufflePlayerTiles,
        },
        {
          text: 'Clear',
          isShown: this.moveTiles.length > 0,
          onClick: this.resetAllTiles,
        },
        {
          text: 'Play',
          isShown: this.moveTiles.length > 0,
          onClick: this.makeMoveFromCache,
        },
      ].filter(({ isShown }) => isShown);
    },

    tooltipIndex() {
      return this.cachedMove?.words?.[0]?.tiles?.at(-1).cellIndex || -1;
    },

    turnPlayer() {
      return this.turnPlayerIndex === 0 ? this.player : this.opponent;
    },

    turnOpponent() {
      return this.turnPlayerIndex === 0 ? this.opponent : this.player;
    },

    turnPlayerName() {
      return this.turnPlayerIndex === 0 ? 'You' : 'Opponent';
    },
  },

  methods: {
    /* CLICK EVENTS */

    onClickTile(tile: Tile, cellIndex = -1) {
      if (!this.selectedCelledTile) {
        this.selectedCelledTile = { cellIndex, tile };
        return;
      } else if (this.selectedCelledTile.tile[0] !== tile[0]) {
        this.switchTiles(this.selectedCelledTile, {
          cellIndex,
          tile,
        });
      }
      this.selectedCelledTile = null;
    },

    onClickCell(cellIndex = -1) {
      if (!this.selectedCelledTile) return;

      const { cellIndex: selectedCellIndex, tile: selectedTile } =
        this.selectedCelledTile;

      if (selectedCellIndex === -1) {
        this.cutTileFromPlayerTiles(selectedTile[0]);
      } else {
        this.cutTileFromMoveTiles(selectedTile[0]);
      }

      if (cellIndex === -1) {
        this.playerTiles.push(selectedTile);
      } else {
        this.moveTiles.push({ cellIndex, tile: selectedTile });
      }

      this.handleMoveTilesChange();

      this.selectedCelledTile = null;
    },

    onClickFooterCell() {
      this.selectedCelledTile ? this.onClickCell() : this.resetAllTiles();
    },

    /* moveTiles */

    getMoveTile(cellIndex: number) {
      return this.moveTiles.find((moveTile) => moveTile.cellIndex == cellIndex)
        ?.tile;
    },

    setMoveTile(targetCellIndex: number, newTile: Tile) {
      const targetIndex = this.moveTiles.findIndex(
        ({ cellIndex }) => cellIndex === targetCellIndex
      );
      if (targetIndex === -1) return;
      this.moveTiles[targetIndex].tile = newTile;
    },

    cutTileFromMoveTiles(tileId: number) {
      this.moveTiles.splice(
        this.moveTiles.findIndex(({ tile }) => tile[0] === tileId),
        1
      );
    },

    handleMoveTilesChange() {
      this.moveTiles.sort((a, b) => a.cellIndex - b.cellIndex);
      this.cacheMove();
    },

    /* playerTiles */

    getPlayerTile(index: number) {
      return this.playerTiles[index - 1];
    },

    setPlayerTile(targetId: number, newTile: Tile) {
      this.playerTiles[
        this.playerTiles.findIndex((tile) => tile[0] === targetId)
      ] = newTile;
    },

    cutTileFromPlayerTiles(tileId: number) {
      this.playerTiles.splice(
        this.playerTiles.findIndex((tile) => tile[0] === tileId),
        1
      );
    },

    shufflePlayerTiles() {
      shuffleArray(this.playerTiles);
    },

    switchPlayerTiles(firstTileId: number, secondTileId: number) {
      const firstIndex = this.playerTiles.findIndex(
          (tile) => tile[0] === firstTileId
        ),
        secondIndex = this.playerTiles.findIndex(
          (tile) => tile[0] === secondTileId
        );
      [this.playerTiles[firstIndex], this.playerTiles[secondIndex]] = [
        this.playerTiles[secondIndex],
        this.playerTiles[firstIndex],
      ];
    },

    /* TILES */

    switchTiles(first: CelledTile, second: CelledTile) {
      if (first.cellIndex === second.cellIndex) {
        this.switchPlayerTiles(first.tile[0], second.tile[0]);
      } else {
        for (const [targetCellIndex, targetId, newTile] of [
          [first.cellIndex, first.tile[0], second.tile],
          [second.cellIndex, second.tile[0], first.tile],
        ]) {
          if (targetCellIndex === -1) {
            this.setPlayerTile(targetId, newTile);
          } else {
            this.setMoveTile(targetCellIndex, newTile);
          }
        }
        this.handleMoveTilesChange();
      }
    },

    resetAllTiles() {
      this.moveTiles = [];
      this.playerTiles = [...this.player.tiles];
    },

    /* WORDS */

    getWordString(words: WordClass[]) {
      return words
        .map((word) => word.tiles.map(({ tile }) => tile[1]).join(''))
        .join(', ');
    },

    /* MOVE */

    cacheMove() {
      try {
        const { words, points } = new Move(this.match.grid, this.moveTiles);
        this.cachedMove = {
          newMove: {
            playerIndex: PLAYER_INDEX,
            moveTiles: this.moveTiles,
            points,
          },
          words,
        };
      } catch (error) {
        this.cachedMove = { error };
      }
    },

    makeMoveFromCache() {
      const { newMove, words, error } = this.cachedMove;
      if (error) {
        const { message } = error as GameError;
        console.error(error);
        pushToast({
          body: message,
          mood: 'error',
        });
        return;
      }
      this.cachedMove = null;

      this.match.handleMoveSave(newMove);
      this.handleMoveEnd(words, newMove.points);
    },

    async makeAutoMove() {
      const startTime = +new Date();
      try {
        const { moveTiles, words, points } = new AutoMove(
          this.match.grid,
          this.opponent.tiles
        );
        const elapsedTime = +new Date() - startTime;
        if (elapsedTime < AUTO_MOVE_DELAY) {
          await sleep(AUTO_MOVE_DELAY - elapsedTime);
        }

        this.match.handleMoveSave({
          playerIndex: OPPONENT_INDEX,
          moveTiles: moveTiles,
          points,
        });
        this.handleMoveEnd(words, points);
      } catch (error) {
        this.handleMoveEnd([], 0);
      }
    },

    handleMoveEnd(words: WordClass[], points: number) {
      // empty values on pass
      // TODO test

      if (this.turnPlayer.tiles.length === 0) {
        const scoreDiff = this.turnPlayer.score - this.turnOpponent.score;
        if (scoreDiff === 0) {
          this.turnPlayer.setIsWinner(true);
          this.turnOpponent.setIsWinner(true);
        } else if (scoreDiff > 0) {
          this.turnPlayer.setIsWinner(true);
        } else {
          this.turnOpponent.setIsWinner(true);
        }
        return;
      }

      if (words.length === 0 && points === 0) {
        if (this.turnPlayer.isPassed) {
          this.turnOpponent.setIsWinner(true);
          return;
        } else {
          this.turnPlayer.setIsPassed(true);
        }
      }

      this.resetAllTiles();

      if (words.length > 0) {
        pushToast({
          body: `${this.turnPlayerName} played ${this.getWordString(
            words
          )} for ${points} pts`,
          mood: 'success',
        });
      } else {
        pushToast({
          body: `${this.turnPlayerName} passed`,
          mood: 'warning',
        });
      }

      this.turnPlayerIndex = this.turnPlayerIndex == 0 ? 1 : 0;
      if (this.turnPlayerIndex === 1) this.makeAutoMove();
    },

    /* MATCH */

    async resignMatch() {
      const { isConfirmed } = await triggerDialog({
        title: 'Resignation',
        body: 'Are you sure you want to resign?',
        confirmButtonText: 'Resign',
        accent: 'danger',
      });
      if (isConfirmed) this.opponent.setIsWinner(true);
    },

    async passMatch() {
      if (!this.player.isPassed) {
        this.handleMoveEnd([], 0);
        return;
      }
      const { isConfirmed } = await triggerDialog({
        title: 'Pass',
        body: 'If you pass now, you will lose the game. Pass anyway?',
        confirmButtonText: 'Pass',
        accent: 'danger',
      });
      if (isConfirmed) this.opponent.setIsWinner(true);
    },
  },
});
