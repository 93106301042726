const getDefaultState = () =>
  ({
    dialog: {
      title: '',
      body: '',
      confirmButtonText: '',
      accent: 'secondary',
      isConfirmed: undefined,
      isTriggered: false,
    },
    toasts: [],
  } as State);

export { getDefaultState };
