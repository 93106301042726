
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GameButton',

  props: {
    text: {
      type: String,
      default: '',
    },
    accent: {
      type: String as () => 'secondary' | 'danger',
      default: 'secondary',
    },
  },
});
