export default {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = ($event: Event) => {
      $event.stopPropagation();
      if (el !== $event.target && !el.contains($event.target))
        binding.value($event, el);
    };
    window.requestAnimationFrame(() =>
      document.addEventListener('click', el.clickOutsideEvent)
    );
  },
  unmounted: (el) =>
    document.removeEventListener('click', el.clickOutsideEvent),
};
