
import { defineComponent } from 'vue';
import { state } from '@/store/state';

export default defineComponent({
  name: 'TheToastGroup',

  data() {
    return {
      isTouched: false,
    };
  },

  computed: {
    toasts() {
      return state.toasts;
    },
  },
});
