import { DICTIONARY, TILE_POINTS } from '@/game/classes/constants';
import { validate } from './utils';

export class Word implements WordClass {
  tiles;
  points;
  error;

  constructor({ cellIndex, axis, moveTiles }) {
    try {
      this.setTiles(cellIndex, axis, moveTiles);
      this.validateTilesLength();
      this.validateTilesInDictionary();
      this.setPoints(axis);
    } catch (error) {
      const { message, isCritical } = error as GameError;
      this.error = message;
      if (isCritical) throw error; // pass error to IndexPage try/catch if is critical
    }
  }

  private setTiles(cellIndex: number, axis: Cell[], moveTiles: CelledTile[]) {
    this.tiles = [];
    setTiles: for (let i = 0; i < axis.length; i++) {
      const cell = {
        cellIndex: axis[i].index,
        tile:
          axis[i]?.tile ||
          moveTiles.find((celledTile) => celledTile.cellIndex === axis[i].index)
            ?.tile,
      };
      if (cell.tile) {
        this.tiles.push(cell);
      } else if (this.tiles.length > 0) {
        const tilesHaveTileWithMainCellIndex = this.tiles.some(
          (celledTile) => celledTile.cellIndex === cellIndex
        );
        if (tilesHaveTileWithMainCellIndex) {
          break setTiles;
        } else {
          this.tiles.length = 0;
        }
      }
    }
  }

  private validateTilesLength() {
    validate({
      condition: this.tiles.length < 2,
      message: 'Word too short',
    });
  }

  private validateTilesInDictionary() {
    const wordString = this.tiles.map(({ tile }) => tile[1]).join('');
    validate({
      condition: !DICTIONARY.includes(wordString),
      message: `${wordString} not in dictionary`,
      isCritical: true,
    });
  }

  private setPoints(axis: Cell[]) {
    this.points = this.tiles.reduce(
      (total, { tile, cellIndex }, iterationIndex) => {
        let points = TILE_POINTS[tile[1]];

        const cell = axis.find(({ index }) => index === cellIndex);
        if (cell.bonus && !cell.tile) {
          const multiplier = cell.bonus.startsWith('D') ? 2 : 3;
          if (cell.bonus.endsWith('W')) total.multipliers.push(multiplier);
          else points *= multiplier;
        }

        total.points += points;
        if (iterationIndex === this.tiles.length - 1)
          total.multipliers.forEach((bonus) => (total.points *= bonus));

        return total;
      },
      { points: 0, multipliers: [] }
    ).points;
  }
}
