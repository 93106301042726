<template>
  <path
    fill="#5b5b5b"
    d="M30 18L18 18L18 6ZM30 18L30 6L42 6ZM30 42L42 42L42 54ZM30 42L30 54L18 54ZM18 30L6 30L6 18ZM42 30L42 18L54 18ZM42 30L54 30L54 42ZM18 30L18 42L6 42Z"
  /><path
    fill="#eaeaea"
    d="M6 6L18 6L18 12ZM54 6L54 18L48 18ZM54 54L42 54L42 48ZM6 54L6 42L12 42Z"
  /><path
    fill="#8b84d6"
    d="M18 18L30 18L30 30L18 30ZM22 29L29 29L29 22L22 22ZM42 18L42 30L30 30L30 18ZM31 22L31 29L38 29L38 22ZM42 42L30 42L30 30L42 30ZM38 31L31 31L31 38L38 38ZM18 42L18 30L30 30L30 42ZM29 38L29 31L22 31L22 38Z"
  />
</template>
