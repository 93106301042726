export class Player implements PlayerClass {
  score;
  tiles;
  isPassed;
  isWinner;

  constructor() {
    this.score = 0;
    this.tiles = [];
    this.isPassed = false;
    this.isWinner = false;
  }

  setScore(points: number) {
    this.score += points;
  }

  setIsPassed(value: boolean) {
    this.isPassed = value;
  }

  setIsWinner(value: boolean) {
    this.isWinner = value;
  }
}
