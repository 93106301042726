import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87c608ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "GamePlayer__info" }
const _hoisted_2 = { class: "Text__normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GameAvatar = _resolveComponent("GameAvatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'GamePlayer',
      {
        'GamePlayer--opponent': _ctx.isOpponent,
        'GamePlayer--active': _ctx.isActive,
      },
    ])
  }, [
    _createVNode(_component_GameAvatar, {
      class: "GamePlayer__avatar",
      name: `Avatar${_ctx.playerAvatarIndex}`
    }, null, 8, ["name"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.playerName), 1),
      _createElementVNode("p", {
        ref: "score",
        class: "Text__big"
      }, _toDisplayString(_ctx.score), 513)
    ])
  ], 2))
}