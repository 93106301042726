
import TheDialog from '@/layout/TheDialog.vue';
import TheToastGroup from '@/layout/TheToastGroup.vue';
import GameRoot from '@/game/GameRoot.vue';

export default {
  name: 'App',
  components: {
    TheDialog,
    TheToastGroup,
    GameRoot,
  },
};
