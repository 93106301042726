<template>
  <path
    fill="#eaeaea"
    d="M18 6L30 6L30 18ZM42 6L42 18L30 18ZM42 54L30 54L30 42ZM18 54L18 42L30 42ZM6 18L18 18L18 30ZM54 18L54 30L42 30ZM54 42L42 42L42 30ZM6 42L6 30L18 30Z"
  /><path
    fill="#663db7"
    d="M8 12a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M44 12a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M44 48a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M8 48a4,4 0 1,1 8,0a4,4 0 1,1 -8,0"
  /><path
    fill="#9f84d6"
    d="M22.8 30a7.2,7.2 0 1,1 14.4,0a7.2,7.2 0 1,1 -14.4,0"
  />
</template>
