<template>
  <path
    fill="#e3e3e3"
    d="M20 12a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M32 12a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M32 48a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M20 48a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M8 24a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M44 24a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M44 36a4,4 0 1,1 8,0a4,4 0 1,1 -8,0M8 36a4,4 0 1,1 8,0a4,4 0 1,1 -8,0"
  /><path
    fill="#59c792"
    d="M12 6L18 12L12 18L6 12ZM54 12L48 18L42 12L48 6ZM48 54L42 48L48 42L54 48ZM6 48L12 42L18 48L12 54Z"
  /><path
    fill="#464646"
    d="M18 18L30 18L30 19.9L25 30L18 30ZM42 18L42 30L40.1 30L30 25L30 18ZM42 42L30 42L30 40.1L35 30L42 30ZM18 42L18 30L19.9 30L30 35L30 42Z"
  />
</template>
