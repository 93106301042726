import { GRID_CELLS_CENTER_INDEX } from '@/game/classes/constants';
import { Word } from '.';
import { validate } from './utils';

export class Move implements MoveClass {
  grid;
  tiles;
  primaryAxis;
  secondaryAxis;
  words;
  points;

  constructor(grid: GridClass, tiles: CelledTile[]) {
    this.grid = grid;
    this.tiles = tiles;
    this.words = [];
    this.setPrimaryAndSecondaryAxis();
    this.setWordsFromFirstCelledTile();
    this.validateCentralCellPlacement();
    this.validateWordsLength();
    this.validateAllTiles();
    this.setWordsFromOtherTiles();
    this.setPoints();
  }

  private setPrimaryAndSecondaryAxis() {
    const firstCelledTileIndex = this.tiles[0].cellIndex;
    const secondCelledTileIndex = this.tiles[1]?.cellIndex;
    this.primaryAxis =
      !secondCelledTileIndex ||
      Math.abs(firstCelledTileIndex - secondCelledTileIndex) === 1
        ? 'x'
        : 'y';
    this.secondaryAxis = this.primaryAxis === 'x' ? 'y' : 'x';
  }

  private setWordsFromFirstCelledTile() {
    for (const axisType of [this.primaryAxis, this.secondaryAxis]) {
      const word = new Word({
        cellIndex: this.tiles[0].cellIndex,
        axis: this.grid.getAxisCells(
          this.tiles[0].cellIndex,
          axisType as AxisType
        ),
        moveTiles: this.tiles,
      });
      if (!word.error) this.words.push(word);
    }
  }

  private validateCentralCellPlacement() {
    if (this.grid.cells.some((cell) => cell.lastPlayed)) return;
    validate({
      condition: !this.tiles.some(
        ({ cellIndex }) => cellIndex === GRID_CELLS_CENTER_INDEX
      ),
      message: 'First word must be placed on center tile',
    });
  }

  private validateWordsLength() {
    validate({
      condition: this.words.length === 0,
      message: 'No valid words found',
    });
  }

  private validateAllTiles() {
    this.tiles.forEach(({ cellIndex }) => {
      const celledTileIsInFirstWord = this.words[0].tiles.some(
        (celledTile) => celledTile.cellIndex === cellIndex
      );
      if (!celledTileIsInFirstWord)
        validate({
          condition: true,
          message: 'All tiles must be connected',
        });
    });
  }

  private setWordsFromOtherTiles() {
    for (let i = 1; i < this.tiles.length; i++) {
      const word = new Word({
        cellIndex: this.tiles[i].cellIndex,
        axis: this.grid.getAxisCells(
          this.tiles[i].cellIndex,
          this.secondaryAxis
        ),
        moveTiles: this.tiles,
      });
      if (!word.error) this.words.push(word);
    }
  }

  private setPoints() {
    this.points = this.words.reduce((total, { points }) => total + points, 0);
  }
}
