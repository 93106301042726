<template>
  <path
    fill="#545454"
    d="M18 6L30 6L30 18ZM42 6L42 18L30 18ZM42 54L30 54L30 42ZM18 54L18 42L30 42ZM6 18L18 18L18 30ZM54 18L54 30L42 30ZM54 42L42 42L42 30ZM6 42L6 30L18 30Z"
  /><path
    fill="#e4bae8"
    d="M6 18L6 6L12 6ZM42 6L54 6L54 12ZM54 42L54 54L48 54ZM18 54L6 54L6 48Z"
  /><path
    fill="#c975d1"
    d="M30 18L30 27L24 18ZM42 30L33 30L42 24ZM30 42L30 33L36 42ZM18 30L27 30L18 36Z"
  />
</template>
