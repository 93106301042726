const TILE_SVG_PATHS: TileSvgPaths = {
  A: {
    letter:
      'M10.4565 15H11.9443L9.18896 7.24951H7.63135L4.88135 15H6.2832L6.94922 12.9966H9.80127L10.4565 15ZM8.33496 8.70508H8.42627L9.479 11.9277H7.27148L8.33496 8.70508Z',
    points:
      'M15.4766 9H16.1797V3.36328H15.4805L13.9805 4.44141V5.18359L15.4141 4.14453H15.4766V9Z',
  },
  B: {
    letter:
      'M7.77002 15C9.42432 15 10.4341 14.1621 10.4341 12.7979C10.4341 11.7881 9.71436 11.0361 8.67236 10.9395V10.8428C9.45117 10.7192 10.0527 9.99951 10.0527 9.18311C10.0527 7.99072 9.1665 7.24951 7.69482 7.24951H4.45605V15H7.77002ZM5.8418 8.3291H7.35645C8.19434 8.3291 8.68311 8.73193 8.68311 9.4248C8.68311 10.1338 8.16211 10.5151 7.1792 10.5151H5.8418V8.3291ZM5.8418 13.9204V11.498H7.39941C8.45752 11.498 9.01611 11.9116 9.01611 12.7012C9.01611 13.4961 8.47363 13.9204 7.45312 13.9204H5.8418Z',
    points:
      'M15.7031 9H16.3906V7.83203H17.1914V7.20312H16.3906V3.36328H15.3711C14.582 4.53906 13.7188 5.91406 12.957 7.19141V7.83203H15.7031V9ZM13.6758 7.16016C14.2891 6.125 15.0273 4.96094 15.6602 4.01562H15.707V7.20703H13.6758V7.16016Z',
  },
  C: {
    letter:
      'M7.18945 15.1934C8.97803 15.1934 10.2886 14.1353 10.4712 12.5669H9.10693C8.91895 13.437 8.17236 13.9902 7.18945 13.9902C5.86816 13.9902 5.04639 12.8892 5.04639 11.1221C5.04639 9.36035 5.86816 8.25928 7.18408 8.25928C8.16162 8.25928 8.9082 8.86621 9.10156 9.80078H10.4658C10.3047 8.19482 8.9458 7.05615 7.18408 7.05615C4.9873 7.05615 3.62842 8.6084 3.62842 11.1274C3.62842 13.6357 4.99268 15.1934 7.18945 15.1934Z',
    points:
      'M16.2031 9H16.8906V7.83203H17.6914V7.20312H16.8906V3.36328H15.8711C15.082 4.53906 14.2188 5.91406 13.457 7.19141V7.83203H16.2031V9ZM14.1758 7.16016C14.7891 6.125 15.5273 4.96094 16.1602 4.01562H16.207V7.20703H14.1758V7.16016Z',
  },
  D: {
    letter:
      'M4.45605 7.24951V15H7.36182C9.68213 15 11.0088 13.5767 11.0088 11.0898C11.0088 8.65674 9.66602 7.24951 7.36182 7.24951H4.45605ZM5.8418 8.41504H7.1792C8.69922 8.41504 9.59619 9.40332 9.59619 11.1113C9.59619 12.8569 8.7207 13.8345 7.1792 13.8345H5.8418V8.41504Z',
    points:
      'M14.0938 4.95312H14.7734C14.7734 4.28516 15.2227 3.84375 15.8984 3.84375C16.5625 3.84375 16.9648 4.28906 16.9648 4.88672C16.9648 5.38281 16.7539 5.67578 16.0352 6.45312L14.1289 8.51562V9H17.7773V8.36719H15.1055V8.30469L16.3867 6.98047C17.4023 5.92969 17.6836 5.46094 17.6836 4.8125C17.6836 3.89844 16.9297 3.23047 15.9453 3.23047C14.8672 3.23047 14.0938 3.94922 14.0938 4.95312Z',
  },
  E: {
    letter:
      'M10.9727 13.8345H7.3418V11.6162H10.7739V10.5151H7.3418V8.41504H10.9727V7.24951H5.95605V15H10.9727V13.8345Z',
    points:
      'M14.9766 9H15.6797V3.36328H14.9805L13.4805 4.44141V5.18359L14.9141 4.14453H14.9766V9Z',
  },
  F: {
    letter:
      'M6.3418 15V11.8364H9.59131V10.7085H6.3418V8.41504H9.89746V7.24951H4.95605V15H6.3418Z',
    points:
      'M15.2031 9H15.8906V7.83203H16.6914V7.20312H15.8906V3.36328H14.8711C14.082 4.53906 13.2188 5.91406 12.457 7.19141V7.83203H15.2031V9ZM13.1758 7.16016C13.7891 6.125 14.5273 4.96094 15.1602 4.01562H15.207V7.20703H13.1758V7.16016Z',
  },
  G: {
    letter:
      'M10.6592 11.9062V10.9395H7.44189V11.9976H9.30566L9.30029 12.1587C9.27881 13.2544 8.47314 13.9902 7.2915 13.9902C5.91113 13.9902 5.04639 12.8945 5.04639 11.1113C5.04639 9.35498 5.89502 8.25928 7.24854 8.25928C8.24219 8.25928 8.93506 8.75342 9.21436 9.65039H10.5947C10.3477 8.07129 9.03711 7.05615 7.24854 7.05615C5.04102 7.05615 3.62842 8.64062 3.62842 11.1221C3.62842 13.6304 5.0249 15.1934 7.27002 15.1934C9.33789 15.1934 10.6592 13.9097 10.6592 11.9062Z',
    points:
      'M14.8125 6.40234H15.5117C16.3047 6.40234 16.793 6.80859 16.793 7.46094C16.793 8.09375 16.2656 8.51953 15.5234 8.51953C14.7969 8.51953 14.2891 8.14062 14.2266 7.54688H13.5469C13.5977 8.50391 14.3867 9.13281 15.5312 9.13281C16.6523 9.13281 17.5156 8.42578 17.5156 7.48047C17.5156 6.69531 17.0312 6.19922 16.3008 6.08203V6.01953C16.8789 5.85156 17.3008 5.40234 17.3047 4.72266C17.3086 3.93359 16.668 3.23047 15.5547 3.23047C14.4141 3.23047 13.7148 3.90625 13.6445 4.82422H14.3281C14.3867 4.19141 14.8281 3.84375 15.5078 3.84375C16.1875 3.84375 16.5898 4.27344 16.5898 4.80078C16.5898 5.38672 16.1289 5.80078 15.4805 5.80078H14.8125V6.40234Z',
  },
  H: {
    letter:
      'M10.4927 15V7.24951H9.1123V10.4722H5.3418V7.24951H3.95605V15H5.3418V11.6377H9.1123V15H10.4927Z',
    points:
      'M16.2031 9H16.8906V7.83203H17.6914V7.20312H16.8906V3.36328H15.8711C15.082 4.53906 14.2188 5.91406 13.457 7.19141V7.83203H16.2031V9ZM14.1758 7.16016C14.7891 6.125 15.5273 4.96094 16.1602 4.01562H16.207V7.20703H14.1758V7.16016Z',
  },
  I: {
    letter: 'M8.8418 15V7.24951H7.45605V15H8.8418Z',
    points:
      'M13.4766 9H14.1797V3.36328H13.4805L11.9805 4.44141V5.18359L13.4141 4.14453H13.4766V9Z',
  },
  J: {
    letter:
      'M3.42432 12.916C3.42432 14.2964 4.42334 15.1934 5.90576 15.1934C7.46338 15.1934 8.40332 14.2856 8.40332 12.7012V7.24951H7.01758V12.6904C7.01758 13.5337 6.61475 13.9795 5.88965 13.9795C5.229 13.9795 4.78857 13.5552 4.77783 12.916H3.42432Z',
    points:
      'M11.9766 9H12.6797V3.36328H11.9805L10.4805 4.44141V5.18359L11.9141 4.14453H11.9766V9ZM16.2578 9.13281C17.5234 9.13281 18.25 8.0625 18.25 6.19141C18.25 4.32422 17.5117 3.23047 16.2578 3.23047C14.9961 3.23047 14.2578 4.32031 14.2578 6.17969C14.2578 8.05469 14.9883 9.13281 16.2578 9.13281ZM16.2578 8.51953C15.4258 8.51953 14.9648 7.6875 14.9648 6.17969C14.9648 4.69141 15.4336 3.84766 16.2578 3.84766C17.082 3.84766 17.543 4.68359 17.543 6.17969C17.543 7.69141 17.0898 8.51953 16.2578 8.51953Z',
  },
  K: {
    letter:
      'M6.3418 15V12.5078L7.16895 11.5464L9.61816 15H11.3101L8.15186 10.6279L11.0791 7.24951H9.48926L6.43311 10.832H6.3418V7.24951H4.95605V15H6.3418Z',
    points:
      'M15.5039 9.13281C16.6562 9.13281 17.4414 8.33594 17.4414 7.17578C17.4414 6.05859 16.6602 5.31641 15.7148 5.31641C15.1523 5.31641 14.7344 5.48828 14.4609 5.82812H14.3984L14.5742 3.99219H17.1523V3.36328H14.0234L13.6992 6.58594H14.3594C14.582 6.16797 15.0039 5.92188 15.5391 5.92188C16.25 5.92188 16.7383 6.43359 16.7383 7.19531C16.7383 7.98438 16.25 8.51562 15.5117 8.51562C14.8477 8.51562 14.3633 8.11328 14.3008 7.51172H13.6094C13.6719 8.47656 14.4375 9.13281 15.5039 9.13281Z',
  },
  L: {
    letter: 'M10.8975 13.813H7.3418V7.24951H5.95605V15H10.8975V13.813Z',
    points:
      'M14.9766 9H15.6797V3.36328H14.9805L13.4805 4.44141V5.18359L14.9141 4.14453H14.9766V9Z',
  },
  M: {
    letter:
      'M11.459 15V7.24951H9.83691L7.50586 13.002H7.40918L5.07812 7.24951H3.45605V15H4.74512V9.50537H4.82031L6.96338 14.7583H7.95166L10.0947 9.50537H10.1699V15H11.459Z',
    points:
      'M15.3125 6.40234H16.0117C16.8047 6.40234 17.293 6.80859 17.293 7.46094C17.293 8.09375 16.7656 8.51953 16.0234 8.51953C15.2969 8.51953 14.7891 8.14062 14.7266 7.54688H14.0469C14.0977 8.50391 14.8867 9.13281 16.0312 9.13281C17.1523 9.13281 18.0156 8.42578 18.0156 7.48047C18.0156 6.69531 17.5312 6.19922 16.8008 6.08203V6.01953C17.3789 5.85156 17.8008 5.40234 17.8047 4.72266C17.8086 3.93359 17.168 3.23047 16.0547 3.23047C14.9141 3.23047 14.2148 3.90625 14.1445 4.82422H14.8281C14.8867 4.19141 15.3281 3.84375 16.0078 3.84375C16.6875 3.84375 17.0898 4.27344 17.0898 4.80078C17.0898 5.38672 16.6289 5.80078 15.9805 5.80078H15.3125V6.40234Z',
  },
  N: {
    letter:
      'M6.29346 15V9.66113H6.38477L10.1875 15H11.3853V7.24951H10.0532V12.5884H9.96191L6.15918 7.24951H4.95605V15H6.29346Z',
    points:
      'M15.9766 9H16.6797V3.36328H15.9805L14.4805 4.44141V5.18359L15.9141 4.14453H15.9766V9Z',
  },
  O: {
    letter:
      'M8.29688 7.05615C6.04639 7.05615 4.62842 8.61914 4.62842 11.1221C4.62842 13.6196 6.0249 15.1934 8.29688 15.1934C10.5581 15.1934 11.96 13.6196 11.96 11.1221C11.96 8.61914 10.5527 7.05615 8.29688 7.05615ZM8.29688 8.25391C9.67188 8.25391 10.5474 9.36572 10.5474 11.1221C10.5474 12.8677 9.67725 13.9956 8.29688 13.9956C6.90039 13.9956 6.04639 12.8677 6.04639 11.1221C6.04639 9.36572 6.92188 8.25391 8.29688 8.25391Z',
    points:
      'M15.9766 9H16.6797V3.36328H15.9805L14.4805 4.44141V5.18359L15.9141 4.14453H15.9766V9Z',
  },
  P: {
    letter:
      'M4.45605 7.24951V15H5.8418V12.438H7.52295C9.09131 12.438 10.187 11.3906 10.187 9.85986C10.187 8.30225 9.12891 7.24951 7.58203 7.24951H4.45605ZM5.8418 8.38281H7.2168C8.21582 8.38281 8.77979 8.89844 8.77979 9.85986C8.77979 10.7944 8.19971 11.3208 7.21143 11.3208H5.8418V8.38281Z',
    points:
      'M15.7031 9H16.3906V7.83203H17.1914V7.20312H16.3906V3.36328H15.3711C14.582 4.53906 13.7188 5.91406 12.957 7.19141V7.83203H15.7031V9ZM13.6758 7.16016C14.2891 6.125 15.0273 4.96094 15.6602 4.01562H15.707V7.20703H13.6758V7.16016Z',
  },
  Q: {
    letter:
      'M6.30225 15.1934C6.79639 15.1934 7.2583 15.1182 7.67188 14.9731L8.29492 15.8057H9.64844L8.6333 14.4307C9.48193 13.7754 9.95996 12.6099 9.95996 11.1274C9.95996 8.61914 8.54199 7.05615 6.30225 7.05615C4.05176 7.05615 2.62842 8.61377 2.62842 11.1274C2.62842 13.6465 4.03564 15.1934 6.30225 15.1934ZM6.30225 14.0278C4.90576 14.0278 4.01953 12.916 4.01953 11.1274C4.01953 9.34424 4.91113 8.22168 6.30225 8.22168C7.68262 8.22168 8.56885 9.34424 8.56885 11.1274C8.56885 12.1104 8.30029 12.8945 7.82764 13.3457L7.24219 12.5723H5.8833L6.90918 13.9473C6.71582 14.0063 6.51172 14.0278 6.30225 14.0278Z',
    points:
      'M12.9766 9H13.6797V3.36328H12.9805L11.4805 4.44141V5.18359L12.9141 4.14453H12.9766V9ZM17.2578 9.13281C18.5234 9.13281 19.25 8.0625 19.25 6.19141C19.25 4.32422 18.5117 3.23047 17.2578 3.23047C15.9961 3.23047 15.2578 4.32031 15.2578 6.17969C15.2578 8.05469 15.9883 9.13281 17.2578 9.13281ZM17.2578 8.51953C16.4258 8.51953 15.9648 7.6875 15.9648 6.17969C15.9648 4.69141 16.4336 3.84766 17.2578 3.84766C18.082 3.84766 18.543 4.68359 18.543 6.17969C18.543 7.69141 18.0898 8.51953 17.2578 8.51953Z',
  },
  R: {
    letter:
      'M6.8418 12.0835H8.37793L9.89795 15H11.4824L9.79053 11.874C10.7144 11.541 11.2515 10.687 11.2515 9.66113C11.2515 8.17871 10.231 7.24951 8.60352 7.24951H5.45605V15H6.8418V12.0835ZM6.8418 8.37207H8.41016C9.29102 8.37207 9.82812 8.87158 9.82812 9.68799C9.82812 10.5205 9.32324 10.9985 8.44238 10.9985H6.8418V8.37207Z',
    points:
      'M15.4766 9H16.1797V3.36328H15.4805L13.9805 4.44141V5.18359L15.4141 4.14453H15.4766V9Z',
  },
  S: {
    letter:
      'M5.10156 12.8999C5.17139 14.3018 6.34766 15.1934 8.12549 15.1934C10.0054 15.1934 11.1709 14.2588 11.1709 12.7549C11.1709 11.5786 10.5103 10.9233 8.93652 10.5635L8.04492 10.3594C7.0835 10.1338 6.69141 9.82764 6.69141 9.2959C6.69141 8.62451 7.28223 8.18408 8.17383 8.18408C9.02246 8.18408 9.62402 8.61914 9.7207 9.31201H11.0474C10.9829 7.98535 9.80664 7.05615 8.1792 7.05615C6.44434 7.05615 5.29492 7.99072 5.29492 9.38721C5.29492 10.5366 5.95557 11.2295 7.36279 11.5518L8.36719 11.7827C9.36084 12.0137 9.78516 12.3574 9.78516 12.9214C9.78516 13.582 9.12451 14.0601 8.21143 14.0601C7.22852 14.0601 6.54102 13.6089 6.45508 12.8999H5.10156Z',
    points:
      'M15.4766 9H16.1797V3.36328H15.4805L13.9805 4.44141V5.18359L15.4141 4.14453H15.4766V9Z',
  },
  T: {
    letter:
      'M8.77002 15V8.41504H11.1602V7.24951H4.99951V8.41504H7.38428V15H8.77002Z',
    points:
      'M15.4766 9H16.1797V3.36328H15.4805L13.9805 4.44141V5.18359L15.4141 4.14453H15.4766V9Z',
  },
  U: {
    letter:
      'M5.83643 7.24951H4.45068V12.3306C4.45068 14.0225 5.67529 15.1934 7.63574 15.1934C9.59619 15.1934 10.8208 14.0225 10.8208 12.3306V7.24951H9.43506V12.207C9.43506 13.2651 8.78516 13.9849 7.63574 13.9849C6.48633 13.9849 5.83643 13.2651 5.83643 12.207V7.24951Z',
    points:
      'M14.0938 4.95312H14.7734C14.7734 4.28516 15.2227 3.84375 15.8984 3.84375C16.5625 3.84375 16.9648 4.28906 16.9648 4.88672C16.9648 5.38281 16.7539 5.67578 16.0352 6.45312L14.1289 8.51562V9H17.7773V8.36719H15.1055V8.30469L16.3867 6.98047C17.4023 5.92969 17.6836 5.46094 17.6836 4.8125C17.6836 3.89844 16.9297 3.23047 15.9453 3.23047C14.8672 3.23047 14.0938 3.94922 14.0938 4.95312Z',
  },
  V: {
    letter:
      'M8.18359 15L10.8638 7.24951H9.38672L7.45312 13.4155H7.36182L5.41211 7.24951H3.88135L6.58838 15H8.18359Z',
    points:
      'M15.7031 9H16.3906V7.83203H17.1914V7.20312H16.3906V3.36328H15.3711C14.582 4.53906 13.7188 5.91406 12.957 7.19141V7.83203H15.7031V9ZM13.6758 7.16016C14.2891 6.125 15.0273 4.96094 15.6602 4.01562H15.707V7.20703H13.6758V7.16016Z',
  },
  W: {
    letter:
      'M7.4248 9.45166H7.50537L9.08447 15H10.3843L12.4629 7.24951H11.0288L9.70215 13.0664H9.62695L8.08008 7.24951H6.8501L5.32471 13.0664H5.24414L3.91748 7.24951H2.47266L4.55127 15H5.85645L7.4248 9.45166Z',
    points:
      'M17.2031 9H17.8906V7.83203H18.6914V7.20312H17.8906V3.36328H16.8711C16.082 4.53906 15.2188 5.91406 14.457 7.19141V7.83203H17.2031V9ZM15.1758 7.16016C15.7891 6.125 16.5273 4.96094 17.1602 4.01562H17.207V7.20703H15.1758V7.16016Z',
  },
  X: {
    letter:
      'M3.97803 15H5.4873L7.31348 12.1909H7.40479L9.2041 15H10.8047L8.24805 11.1274L10.8477 7.24951H9.29004L7.49609 10.1177H7.40479L5.6377 7.24951H3.99951L6.51318 11.0952L3.97803 15Z',
    points:
      'M15.0547 9.13281C16.25 9.13281 17.0859 8.45703 17.0859 7.49219C17.0859 6.75781 16.6211 6.1875 15.9141 6.05469V5.99219C16.4961 5.83203 16.8555 5.35156 16.8555 4.73438C16.8555 3.85547 16.1094 3.23047 15.0547 3.23047C14 3.23047 13.2539 3.85547 13.2539 4.73438C13.2539 5.34766 13.6211 5.83594 14.1953 5.99219V6.05469C13.4883 6.1875 13.0234 6.75781 13.0234 7.49219C13.0234 8.45703 13.8594 9.13281 15.0547 9.13281ZM15.0547 5.75781C14.3906 5.75781 13.9453 5.375 13.9453 4.80078C13.9453 4.21875 14.3867 3.83203 15.0547 3.83203C15.7227 3.83203 16.1641 4.21875 16.1641 4.80078C16.1641 5.375 15.7188 5.75781 15.0547 5.75781ZM15.0547 8.53125C14.2734 8.53125 13.7344 8.08594 13.7344 7.44141C13.7344 6.79688 14.2734 6.35156 15.0547 6.35156C15.8359 6.35156 16.375 6.79688 16.375 7.44141C16.375 8.08594 15.8359 8.53125 15.0547 8.53125Z',
  },
  Y: {
    letter:
      'M7.98486 15V11.9438L10.7026 7.24951H9.18262L7.34033 10.5742H7.24902L5.40137 7.24951H3.88135L6.59912 11.9438V15H7.98486Z',
    points:
      'M15.7031 9H16.3906V7.83203H17.1914V7.20312H16.3906V3.36328H15.3711C14.582 4.53906 13.7188 5.91406 12.957 7.19141V7.83203H15.7031V9ZM13.6758 7.16016C14.2891 6.125 15.0273 4.96094 15.6602 4.01562H15.707V7.20703H13.6758V7.16016Z',
  },
  Z: {
    letter:
      'M3.24121 15H9.1333V13.8345H4.9707V13.7378L9.03125 8.17334V7.24951H3.354V8.41504H7.31787V8.51172L3.24121 14.0762V15Z',
    points:
      'M12.4766 9H13.1797V3.36328H12.4805L10.9805 4.44141V5.18359L12.4141 4.14453H12.4766V9ZM16.7578 9.13281C18.0234 9.13281 18.75 8.0625 18.75 6.19141C18.75 4.32422 18.0117 3.23047 16.7578 3.23047C15.4961 3.23047 14.7578 4.32031 14.7578 6.17969C14.7578 8.05469 15.4883 9.13281 16.7578 9.13281ZM16.7578 8.51953C15.9258 8.51953 15.4648 7.6875 15.4648 6.17969C15.4648 4.69141 15.9336 3.84766 16.7578 3.84766C17.582 3.84766 18.043 4.68359 18.043 6.17969C18.043 7.69141 17.5898 8.51953 16.7578 8.51953Z',
  },
};

const AUTO_MOVE_DELAY = 1000;

const BOT_NAMES: string[] = [
  'bot-pitt',
  'SirBotalot',
  'ROW-BOT',
  'botovich',
  'Scrabbotle',
  'excalibot',
  'raw-bot',
  'RockMyBot',
  'botman',
  'Robotnic',
  'Boticelli',
  'BOTox',
];

export { TILE_SVG_PATHS, AUTO_MOVE_DELAY, BOT_NAMES };
