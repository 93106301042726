import { getRandomInt } from './general';

function shuffleArray(array: any[]) {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
}

function cutRandomItemsFromArray(array: any[], itemsCut: number): any[] {
  return array.splice(
    getRandomInt({ max: array.length - 1 - itemsCut }),
    itemsCut
  );
}

export { shuffleArray, cutRandomItemsFromArray };
