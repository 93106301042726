
import { defineComponent, PropType } from 'vue';
import { TILE_SVG_PATHS } from './constants';

export default defineComponent({
  name: 'GameTile',

  props: {
    tile: {
      required: true,
      type: Object as PropType<Tile>,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isLastPlayed: {
      type: Boolean,
      default: false,
    },
    isMoveable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tilePath() {
      return TILE_SVG_PATHS[this.tile[1]];
    },
  },
});
