import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4759e7b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "TheDialog__window" }
const _hoisted_3 = { class: "TheDialog__content" }
const _hoisted_4 = { class: "TheDialog__content-title" }
const _hoisted_5 = { class: "Text__small" }
const _hoisted_6 = { class: "TheDialog__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_el_click_outside = _resolveDirective("el-click-outside")!

  return (_openBlock(), _createBlock(_Transition, {
    tag: "div",
    class: "TheDialog",
    name: "fade"
  }, {
    default: _withCtx(() => [
      (_ctx.dialog.isTriggered)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.dialog.title), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.dialog.body), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  class: "Text__small",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resolveDialog(false)))
                }, " Cancel "),
                _createElementVNode("button", {
                  class: "Text__small",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resolveDialog(true)))
                }, _toDisplayString(_ctx.dialog.confirmButtonText), 1)
              ])
            ])), [
              [_directive_el_click_outside, _ctx.onClickOutside]
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}