import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27a9b349"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "GameLayoutCell__bonus",
  viewBox: "0 0 40 40"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['GameLayoutCell', _ctx.isUnclickable && 'GameLayoutCell--unclickable'])
  }, [
    (_ctx.bonus)
      ? (_openBlock(), _createElementBlock("svg", _hoisted_1, [
          _createElementVNode("text", {
            x: "50%",
            y: "56%",
            "text-anchor": "middle",
            "dominant-baseline": "middle",
            class: _normalizeClass(`GameLayoutCell__bonus--${_ctx.bonus}`)
          }, _toDisplayString(_ctx.bonus), 3)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}