import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-466ff472"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "GameHeader PageWidth" }
const _hoisted_2 = { class: "GameHeader__logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GameTile = _resolveComponent("GameTile")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tiles, (tile, i) => {
        return (_openBlock(), _createBlock(_component_GameTile, {
          key: i,
          tile: tile,
          "is-moveable": ""
        }, null, 8, ["tile"]))
      }), 128))
    ])
  ]))
}