<template>
  <path
    fill="#87cc66"
    d="M30 6L30 18L24 18ZM42 18L30 18L30 12ZM30 54L30 42L36 42ZM18 42L30 42L30 48ZM18 18L18 30L12 30ZM54 30L42 30L42 24ZM42 42L42 30L48 30ZM6 30L18 30L18 36Z"
  /><path
    fill="#4c4c4c"
    d="M18 18L6 18L6 6ZM42 18L42 6L54 6ZM42 42L54 42L54 54ZM18 42L18 54L6 54Z"
  /><path
    fill="#c3e5b2"
    d="M18 18L30 18L30 24L18 24ZM18 24L24 24L24 30L18 30ZM30 24L24 30L24 24ZM42 18L42 30L36 30L36 18ZM36 18L36 24L30 24L30 18ZM36 30L30 24L36 24ZM42 42L30 42L30 36L42 36ZM42 36L36 36L36 30L42 30ZM30 36L36 30L36 36ZM18 42L18 30L24 30L24 42ZM24 42L24 36L30 36L30 42ZM24 30L30 36L24 36Z"
  />
</template>
